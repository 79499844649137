import React, { useState, useEffect } from 'react';
import Select, { SingleValue } from 'react-select';
import { VictoryChart, VictoryBar, VictoryLine, VictoryAxis, VictoryVoronoiContainer, VictoryTooltip, VictoryLegend } from 'victory';
import { useTranslation } from 'react-i18next';

interface DataPoint {
  x: Date;
  y: number;
}

interface APIDataPoint {
  datetime: string;
  cash_balance: number;
  full_balance: number;
}

const DailyBalanceChart: React.FC = () => {
  const [timeframe, setTimeframe] = useState('7');
  const [cashData, setCashData] = useState<DataPoint[]>([]);
  const [fullData, setFullData] = useState<DataPoint[]>([]);
  const [currency, setCurrency] = useState(localStorage.getItem('pxlCurrency') || 'USD');
  const { t } = useTranslation();
  const currentHostname = window.location.href;
  const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
  const linkUrl = currentHostname.includes(`${stagingKey}`)
    ? `https://${stagingKey}.peaxel.me`
    : 'https://peaxel.me';

  const walletAddress = localStorage.getItem('walletAddress');
  const devise = localStorage.getItem('pxlCurrency') || 'USD';

  useEffect(() => {
    if (walletAddress) {
      fetch(`${linkUrl}/wp-json/custom/v1/get-daily-balance/?wallet_address=${walletAddress}&timeframe=${timeframe}&currency=${devise}`)
        .then((response) => response.json())
        .then((apiData: APIDataPoint[]) => {
          if (!apiData || apiData.length === 0) {
            console.error("No data returned from API");
            return;
          }

          // Ensure data comes back in the right format
          const cashData = apiData.map((d) => ({
            x: new Date(d.datetime),
            y: d.cash_balance
          }));

          const fullData = apiData.map((d) => ({
            x: new Date(d.datetime),
            y: d.full_balance
          }));

          // Sort the data by date
          const sortedCashData = cashData.sort((a, b) => a.x.getTime() - b.x.getTime());
          const sortedFullData = fullData.sort((a, b) => a.x.getTime() - b.x.getTime());

          setCashData(sortedCashData);
          setFullData(sortedFullData);
        })
        .catch((error) => {
          console.error("Error fetching data from API", error);
        });
    }
  }, [timeframe, currency, walletAddress]);

  const handleCurrencyChange = (selectedOption: SingleValue<{ value: string; label: string }>) => {
    if (selectedOption) {
      setCurrency(selectedOption.value);
      localStorage.setItem('pxlCurrency', selectedOption.value);  // Save to localStorage
    }
  };

  const handleTimeframeChange = (selectedOption: SingleValue<{ value: string; label: string }>) => {
    if (selectedOption) {
      setTimeframe(selectedOption.value);
    }
  };

  const timeframeOptions = [
    { value: 'week', label: t('LastWeek') },
    { value: 'month', label: t('LastMonth') },
    { value: 'year', label: t('LastYear') }
  ];

  const currencyOptions = [
    { value: 'USD', label: 'USD' },
    { value: 'EUR', label: 'EUR' }
  ];

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: '#161616',
      color: '#D8D8D8',
      borderColor: '#343434',
      boxShadow: 'none',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: '#D8D8D8',
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: '#161616',
      color: '#D8D8D8',
    }),
    option: (provided: any) => ({
      ...provided,
      backgroundColor: '#161616',
      color: '#D8D8D8',
      '&:hover': {
        backgroundColor: '#333333',
      },
    }),
  };

  const yMax = Math.max(...cashData.map(d => d.y), ...fullData.map(d => d.y));

  return (
    <div style={{ width: '100%', maxHeight: '250px', padding: '0px', backgroundColor: '#161616', color: '#D8D8D8' }}>
      <VictoryChart
        width={1000}
        height={700} // Fixed height for the chart
        padding={{ top: 250, bottom: 250, left: 50, right: 50 }} // Adjust padding to remove excess space
        domainPadding={{ x: 20, y: 0 }} // Adds uniform padding between bars
        domain={{ y: [0, yMax] }} // Ensure y-axis starts from 0
        containerComponent={
          <VictoryVoronoiContainer
            labels={({ datum }) => `${datum.y}${currency}\n${datum.x.toLocaleDateString()}`}
            labelComponent={
              <VictoryTooltip
                style={{ fontSize: 40, fontWeight: 'bold', fill: '#FFFFFF' }} // Ensures tooltip text is large enough
                flyoutStyle={{ fill: '#161616', padding: 10, borderRadius: 2 }} 
              />
              
            }
          />
        }
      >
        <VictoryBar
          data={cashData}
          style={{
            data: {
              fill: ({ index }) => (index === cashData.length - 1 ? '#d4055d' : "#93003F"), // Last bar different color
              strokeWidth: 2,
              borderRadius: '30', // Rounded top of bars
            },
          }}
          barWidth={({ index }) => 900 / cashData.length - 5} // Uniform bar width
          alignment="middle" // Centers the bars
        />
        <VictoryLine
          data={fullData}
          style={{
            data: {
              stroke: "#FFFFFF",
              strokeWidth: 2,
            },
          }}
        />
        <VictoryAxis
          style={{
            axis: { stroke: "none" },
            ticks: { stroke: "none" },
            tickLabels: { fill: "none" }, // Hiding the labels on the x-axis
            grid: { stroke: "none" }
          }}
        />
        <VictoryAxis
          dependentAxis
          style={{
            axis: { stroke: "none" },
            ticks: { stroke: "none" },
            tickLabels: { fill: "none" }, // Hiding Y-axis labels
            grid: { stroke: "none" }
          }}
        />
        <VictoryLegend x={300} y={550}
          orientation="horizontal"
          gutter={20}
          style={{ 
            labels: { fill: "#D8D8D8", fontSize: 30, opacity: 0.8 },
            border: { stroke: "none" }
          }}
          data={[
            { name: t("CashBalance"), symbol: { fill: "#93003F" } },
            { name: t("TotalBalance"), symbol: { fill: "#FFFFFF", type: "line" } }
          ]}
        />
      </VictoryChart>

      <div className="valueChartSelector">
        <div className="chartSelector2">
          <span 
            onClick={() => setTimeframe('7')} 
            style={{ 
              fontWeight: timeframe === '7' ? 'bold' : 'normal', 
              color: timeframe === '7' ? '#848484' : '#363636', 
              cursor: 'pointer', 
              marginRight: '10px' 
            }}
          >
            {t('LastWeek')}
          </span>
          <span 
            onClick={() => setTimeframe('30')} 
            style={{ 
              fontWeight: timeframe === '30' ? 'bold' : 'normal', 
              color: timeframe === '30' ? '#848484' : '#363636', 
              cursor: 'pointer', 
              marginRight: '10px' 
            }}
          >
            {t('LastMonth')}
          </span>
          <span 
            onClick={() => setTimeframe('365')} 
            style={{ 
              fontWeight: timeframe === '365' ? 'bold' : 'normal', 
              color: timeframe === '365' ? '#848484' : '#363636', 
              cursor: 'pointer' 
            }}
          >
            {t('LastYear')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default DailyBalanceChart;
